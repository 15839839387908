import { Transition } from "@headlessui/react";
import HeroBg from "components/common/images/bg-pattern-2.png";
import HeroStrength from "components/common/images/hero_strength.png";
import { Fragment, forwardRef, useEffect, useState } from "react";

const Hero = () => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    setShow(true);
  }, []);

  const heroImg = forwardRef((props, ref) => {
    return <div ref={ref} {...props} className="md:w-1/2"></div>;
  });

  const heroText = forwardRef((props, ref) => {
    return (
      <div
        ref={ref}
        {...props}
        className="md:w-1/2 text-center md:text-left"
      ></div>
    );
  });

  const heroMain = forwardRef((props, ref) => {
    return (
      <div
        ref={ref}
        {...props}
        className="mx-auto max-w-7xl items-center justify-between md:flex lg:flex lg:justify-normal lg:gap-10"
      ></div>
    );
  });

  return (
    <section className="h-svh net-bg">
      <Transition as={heroMain} show={show} appear={true}>
        <Transition.Child
          as={heroImg}
          enter="transition ease-in duration-1000"
          enterFrom="opacity-0 translate-y-2"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <img src={HeroStrength} />
        </Transition.Child>
        <Transition.Child
          as={heroText}
          enter="transition ease-in duration-1000"
          enterFrom="opacity-0 translate-y-2"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <h1 className="text-4xl font-bold text-gray-500">
            let's get <span className="text-brand-primary">IT</span> done
          </h1>
          <p className="text-xl md:text-2xl lg:text-3xl text-gray-500">
            Choose our IT services for unparalleled expertise and unwavering
            dedication to your success.
          </p>
        </Transition.Child>
      </Transition>
    </section>
  );
};

export default Hero;
