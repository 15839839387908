import { useState } from "react";

import { LogoMain } from "../Logo/Logo";
import { Menu, MenuMobile } from "../Nav/Menu";

export default function () {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <header className="panel-brand-border w-full z-50">
      <nav
        className="mx-auto flex max-w-7xl items-center justify-between lg:justify-normal  p-6 lg:px-8 lg:gap-52"
        aria-label="Global"
      >
        <div className="flex lg:flex">
          <a href="#" className="-m-1.5 p-1.5">
            <span className="sr-only">AdroIT Guru</span>
            <LogoMain />
          </a>
        </div>

        <Menu />
        <MenuMobile
          mobileMenuOpen={mobileMenuOpen}
          setMobileMenuOpen={setMobileMenuOpen}
        />
      </nav>
    </header>
  );
}
