import Companyies from "./common/Sections/Companyies";
import Footer from "./common/Sections/Footer";
import Header from "./common/Sections/Header";
import Hero from "./common/Sections/Hero";

const Layout = () => {
  return (
    <>
      <Header />
      <Hero />
      <Companyies />
      <Footer />
    </>
  );
};

export default Layout;
