import React from "react";
import MenuData from "./MenuData";

const FooterMenu = () => {
  const menuData = MenuData;
  return (
    <div class="mt-6 lg:mt-0 lg:flex-1">
      <div class="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {menuData.map((item, index) => {
          return (
            <div key={index}>
              <h3 class="font-bold">{item.name}</h3>
              {item.submenu.map((subItem, index) => {
                return (
                  <a
                    key={index}
                    href={subItem.href}
                    class="block mt-2 text-sm hover:text-brand-primary"
                  >
                    {subItem.name}
                  </a>
                );
              })}
              {item.callToAction?.length !== 0 &&
                item.callToAction?.map((subItem, index) => {
                  return (
                    <a
                      key={index}
                      href={subItem.href}
                      class="flex gap-2 mt-2 text-sm hover:text-brand-primary"
                    >
                      <subItem.icon className="h-5 w-5" aria-hidden="true" />
                      {subItem.name}
                    </a>
                  );
                })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FooterMenu;
