const VmwareIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      fill="currentColor"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 42.414063 15 C 38.824219 15 36.574219 17.5 36.574219 17.5 C 35.378906 15.941406 33.730469 15.003906 30.941406 15.003906 C 27.996094 15.003906 26.042969 17.5 26.042969 17.5 C 24.847656 15.941406 22.6875 15 21 15 C 18.390625 15 16.320313 16.152344 15.054688 19.058594 L 10.820313 28.320313 L 6.03125 16.558594 C 5.425781 15.226563 3.933594 14.625 2.542969 15.246094 C 1.148438 15.871094 0.636719 17.425781 1.265625 18.757813 L 7.113281 31.945313 C 8.03125 33.949219 9.003906 35 10.820313 35 C 12.765625 35 13.609375 33.855469 14.53125 31.945313 C 14.53125 31.945313 18.511719 23.0625 19 22 C 19.488281 20.9375 20.300781 20 21.5 20 C 22.875 20 24 21.125 24 22.5 L 24 32.375 C 24 33.820313 25.085938 35 26.523438 35 C 27.957031 35 29 33.820313 29 32.375 L 29 22.5 C 29 21.125 30.125 20 31.5 20 C 32.875 20 34 21.125 34 22.5 L 34 32.5 C 34 33.875 35.125 35 36.5 35 C 37.875 35 39 33.875 39 32.5 L 39 22.5 C 39 21.125 40.125 20 41.5 20 C 42.875 20 44 21.125 44 22.5 L 44 32.5 C 44 33.875 45.125 35 46.5 35 C 47.875 35 49 33.875 49 32.5 L 49 21.355469 C 49 17.617188 46.011719 15 42.414063 15 Z"
      />
    </svg>
  );
};

export default VmwareIcon;
