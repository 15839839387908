import ImgLg from "components/common/images/logo-main.png";
import ImgMd from "components/common/images/logo_185x40.png";
import ImgSm from "components/common/images/logo.png";

export const LogoSmall = ({ className }) => {
  return (
    <img className={`w-auto ${className}`} src={ImgSm} alt="AdroIT Guru" />
  );
};

export const LogoMedium = ({ className }) => {
  return (
    <img className={`w-auto ${className}`} src={ImgMd} alt="AdroIT Guru" />
  );
};

export const LogoLarge = ({ className }) => {
  return (
    <img className={`w-auto ${className}`} src={ImgLg} alt="AdroIT Guru" />
  );
};

export const LogoMain = () => {
  return (
    <>
      <LogoSmall className="h-8 lg:hidden md:hidden" />
      <LogoMedium className="h-10 hidden md:block lg:hidden" />
      <LogoLarge className="h-14 hidden lg:block md:hidden" />
    </>
  );
};
