export const SubMenu = ({ data }) => {
  return (
    <div
      key={data.name}
      className="group relative flex items-center gap-x-6 p-4 text-sm leading-6 hover:bg-gray-50 rounded-lg menu-banner"
    >
      <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
        <data.icon
          className="h-6 w-6 text-gray-600 group-hover:text-brand-primary"
          aria-hidden="true"
        />
      </div>
      <div className="flex-auto">
        <a
          href={data.href}
          className="block font-semibold group-hover:text-brand-primary"
        >
          {data.name}
          <span className="absolute inset-0" />
        </a>
        <p className="mt-1 text-gray-500 text-2sm">{data.description}</p>
      </div>
    </div>
  );
};
