import { Disclosure, Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { SubMenu } from "./SubMenu";
import { Fragment } from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const MenuItem = ({ data }) => {
  const submenu = data.submenu;
  const callToAction = data.callToAction || [];
  return (
    <>
      {submenu.length === 0 ? (
        <a
          href={data.href}
          className="font-semibold leading-6 hover:text-brand-primary"
          target={data.target || ""}
        >
          {data.name}
        </a>
      ) : (
        <Popover className="">
          {({ open }) => (
            <>
              <Popover.Button
                className={`flex items-center gap-x-1 font-semibold leading-6 hover:text-brand-primary ${
                  open && "text-brand-primary"
                }`}
              >
                {data.name}
                <ChevronDownIcon
                  className={`h-4 w-4 flex-none ${
                    open && "rotate-180 transform"
                  }`}
                  aria-hidden="true"
                />
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                {data.type === "lg" ? (
                  <Popover.Panel className="menu-height flex items-stretch panel-brand-border -left-1/2 absolute top-14 z-10 mt-3 overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-gray-900/5">
                    <div className="menu-banner">
                      <img className="object-contain" src={data.img} />
                    </div>
                    <div className="flex flex-wrap p-4 menu-content relative">
                      {submenu.map((item, index) => {
                        return <SubMenu key={index} data={item} />;
                      })}
                      <a
                        href={data.viewMore}
                        className="text-2sm anchor-brand absolute right-4 bottom-4"
                      >
                        View more &rarr;
                      </a>
                    </div>
                  </Popover.Panel>
                ) : (
                  <Popover.Panel className="min-w-52 flex flex-col items-stretch panel-brand-border absolute top-14 z-10 mt-3 overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-gray-900/5">
                    <div className="p-2 flex flex-col">
                      {submenu.map((item, index) => {
                        return (
                          <a
                            href={item.href}
                            className="text-sm font-semibold leading-6 hover:text-brand-primary p-2 hover:bg-gray-50 rounded-lg"
                            target={item.target || ""}
                          >
                            {item.name}
                          </a>
                        );
                      })}
                    </div>

                    {callToAction.length !== 0 && (
                      <>
                        <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50 mt-2">
                          {callToAction.map((item) => (
                            <a
                              key={item.name}
                              href={item.href}
                              target={item.target}
                              className="flex text-gray-400 items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 hover:bg-gray-100 hover:text-brand-primary"
                            >
                              <item.icon
                                className="h-5 w-5 flex-none "
                                aria-hidden="true"
                              />
                            </a>
                          ))}
                        </div>
                      </>
                    )}
                  </Popover.Panel>
                )}
              </Transition>
            </>
          )}
        </Popover>
      )}
    </>
  );
};

export const MenuItemMobile = ({ data }) => {
  const submenu = data.submenu;
  return (
    <>
      {submenu.length === 0 ? (
        <a
          href={data.href}
          className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 hover:text-brand-primary"
        >
          {data.name}
        </a>
      ) : (
        <Disclosure as="div" className="-mx-3">
          {({ open }) => (
            <>
              <Disclosure.Button
                className={`flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 hover:text-brand-primary ${
                  open && "text-brand-primary"
                }`}
              >
                {data.name}
                <ChevronDownIcon
                  className={classNames(
                    open ? "rotate-180" : "",
                    "h-5 w-5 flex-none"
                  )}
                  aria-hidden="true"
                />
              </Disclosure.Button>
              <Disclosure.Panel className="mt-2 space-y-2">
                {submenu.map((item, index) => (
                  <a
                    key={index}
                    as="a"
                    href={item.href}
                    className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 hover:text-brand-primary"
                  >
                    {item.name}
                  </a>
                ))}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      )}
    </>
  );
};
