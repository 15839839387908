import React from "react";
import MenuData from "./MenuData";
import { Dialog, Disclosure, Popover } from "@headlessui/react";
import { MenuItem, MenuItemMobile } from "./MenuItem";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/20/solid";
import { LogoSmall } from "../Logo/Logo";

export const Menu = () => {
  return (
    <Popover.Group className="hidden lg:flex lg:gap-x-12 relative">
      {MenuData.map((menuItem, index) => {
        return <MenuItem key={index} data={menuItem} />;
      })}
    </Popover.Group>
  );
};

export const MenuMobile = ({ mobileMenuOpen, setMobileMenuOpen }) => {
  return (
    <>
      <div className="flex lg:hidden">
        <button
          type="button"
          className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
          onClick={() => setMobileMenuOpen(true)}
        >
          <span className="sr-only">Open main menu</span>
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">AdroitIT</span>
              <LogoSmall className="h-8 lg:hidden md:hidden" />
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {MenuData.map((item, index) => {
                  return <MenuItemMobile data={item} key={index} />;
                })}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </>
  );
};
