const VeeamIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      fill="currentColor"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6,6v24.75C6,30.75,6.093,42,17.25,42S42,42,42,42v-8H19c0,0-8-0.125-8-8s0-20,0-20H6z"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15 6L21 6 28.5 24 36 6 42 6 32 30 25 30z"
      />
    </svg>
  );
};

export default VeeamIcon;
