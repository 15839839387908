import {
  ArrowPathIcon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
} from "@heroicons/react/24/outline";

import SolutionImg from "components/common/images/menu_1.png";
import ServiceImg from "components/common/images/menu_2.png";
import VmwareIcon from "../images/VmwareIcon";
import AzureIcon from "../images/AzureIcon";
import AwsIcon from "../images/AwsIcon";
import VeeamIcon from "../images/VeeamIcon";
import {
  CircleStackIcon,
  CloudIcon,
  EnvelopeIcon,
  LifebuoyIcon,
  RectangleGroupIcon,
  RectangleStackIcon,
  ServerStackIcon,
} from "@heroicons/react/20/solid";
import WhatsAppIcon from "../images/WhatsAppIcon";

const MenuData = [
  {
    name: "Why adroit",
    href: "#",
    type: "sm",
    img: SolutionImg,
    viewMore: "#",
    submenu: [
      {
        name: "Who are we?",

        description:
          "Elevate your IT infrastructure with our VMware services. From deployment to optimization, we streamline operations for enhanced performance and scalability.",
        href: "#",
        icon: VmwareIcon,
      },
      {
        name: "Customer Satisfaction",
        description:
          "Elevate your IT infrastructure with our VMware services. From deployment to optimization, we streamline operations for enhanced performance and scalability.",
        href: "#",
        icon: VmwareIcon,
      },
    ],
  },
  {
    name: "Solutions",
    href: "#",
    type: "lg",
    img: SolutionImg,
    viewMore: "#",
    submenu: [
      {
        name: "Vmware",
        description:
          "Elevate your IT infrastructure with our VMware services. From deployment to optimization, we streamline operations for enhanced performance and scalability.",
        href: "#",
        icon: VmwareIcon,
      },
      {
        name: "Azure",
        description:
          "From cloud migration to innovative app development, we offer tailored services to optimize your operations and drive growth in the Microsoft Azure ecosystem.",
        href: "#",
        icon: AzureIcon,
      },
      {
        name: "AWS",
        description:
          "From cloud migration to scalable infrastructure, we provide expert services to optimize your operations and harness the full potential of Amazon Web Services for your business success.",
        href: "#",
        icon: AwsIcon,
      },
      {
        name: "Veeam",
        description:
          "From seamless backup and recovery to comprehensive data protection strategies, we deliver tailored services to ensure business continuity and peace of mind for your organization.",
        href: "#",
        icon: VeeamIcon,
      },
      {
        name: "Veritas",
        description:
          "Maximize your data management capabilities with our Veritas services. From comprehensive backup solutions to advanced data analytics, we offer tailored services to optimize your IT infrastructure.",
        href: "#",
        icon: ServerStackIcon,
      },
    ],
  },
  {
    name: "Services",
    href: "#",
    type: "lg",
    img: ServiceImg,
    viewMore: "#",
    submenu: [
      {
        name: "Solution Design",
        description:
          "From concept to implementation, trust us to architect solutions that propel your success.",
        href: "#",
        icon: LifebuoyIcon,
      },
      {
        name: "Cloud Solutions",
        description:
          "From migration to optimization, we offer comprehensive solutions tailored to your business needs.",
        href: "#",
        icon: CloudIcon,
      },
      {
        name: "Managed Services",
        description:
          "From proactive monitoring to rapid issue resolution, we provide comprehensive support to keep your IT infrastructure running smoothly.",
        href: "#",
        icon: RectangleGroupIcon,
      },
      {
        name: "Data Migration",
        description:
          "Our expert team ensures a smooth and secure transfer of your information, minimizing downtime and ensuring data integrity throughout the process.",
        href: "#",
        icon: RectangleStackIcon,
      },
      {
        name: "Backup & Recovery",
        description:
          "We offer comprehensive solutions to protect your critical data from loss or corruption, ensuring business continuity and peace of mind.",
        href: "#",
        icon: CircleStackIcon,
      },
    ],
  },
  // {
  //   name: "Blog",
  //   href: "https://blog.adroitguru.com",
  //   img: null,
  //   viewMore: "#",
  //   submenu: [],
  //   target: "_blank",
  // },
  {
    name: "Connect now",
    href: "#",
    type: "sm",
    img: null,
    viewMore: "#",
    submenu: [
      {
        name: "Inquiry",
        href: "#",
      },
      {
        name: "Sales",
        href: "#",
      },
      {
        name: "Support",
        href: "#",
      },
    ],
    callToAction: [
      {
        name: "+971 585 (adroit)",
        href: "https://wa.me/97158523768",
        icon: WhatsAppIcon,
        target: "_blank",
      },
      {
        name: "info@adroitguru.com",
        href: "mailto:info@adroitguru.com",
        icon: EnvelopeIcon,
        target: "_blank",
      },
    ],
  },
];

export default MenuData;
